export default interface SrItemStatusCommand {
    srItem: number;
    srStatusType: number;
    srStatusComment?: string;
    srStatusExtension?: number;
}

export enum DogOwnerSrStatusType {
    NoticeIssued = 15069130,
    InEnforcerLite = 15070312,
    Cancel = 15069138,
    WithdrawnByWCC = 15070308,
    LodgedWithCourt = 15069135,
}

export enum DogOwnerSrStatusExtension {
    Unpaid = 1233,
}

export enum GeneralInfringementStatusType {
    LodgedWithCourt = 15070536,
    Completed = 15070538,
}

export enum GeneralInfringementStatusTypeExtension {
    Unpaid = 2484,
}
